<template>
  <Component
    :is="tag"
    :type="type"
    :class="[
      'group relative font-normal transition-colors',
      computedClasses,
      { loading: loading, text: text, icon: icon || iconTransparent, active: active }
    ]"
    :disabled="disabled"
    @click="!loading && !disabled && emit('click', $event)"
  >
    <span v-if="loading" class="absolute inset-center">
      <UiIcon name="spin" class="size-6 animate-spin text-icon-tertiary" />
    </span>
    <slot />
  </Component>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import UiIcon from './UiIcon.vue'

type PropsType = {
  severity?: 'primary' | 'secondary' | 'tertiary' | 'quaternary'
  size?: 'big' | 'small'
  type?: 'button' | 'submit' | 'reset'
  loading?: boolean
  icon?: boolean
  iconTransparent?: boolean
  text?: boolean
  disabled?: boolean
  tag?: string
  active?: boolean
}

type EmitType = {
  (e: 'click', event: MouseEvent): void
}

const props = withDefaults(defineProps<PropsType>(), {
  severity: 'primary',
  size: 'big',
  type: 'button',
  transparent: false,
  disabled: false,
  tag: 'button',
  iconTransparent: false,
  active: false
})
const emit = defineEmits<EmitType>()

const severityClassesMap: Record<NonNullable<PropsType['severity']>, string> = {
  primary: [
    'text-button-primary bg-button-primary border border-button-primary',
    'hover:text-button-primary-hover hover:bg-button-primary-hover hover:border-button-primary-hover',
    'active:text-button-primary-active primary-bg-active active:border-button-primary-active',
    'focus-visible:text-button-primary-focus focus-visible:bg-button-primary-focus focus-visible:border-button-primary-focus'
  ].join(' '),
  secondary: [
    'text-button-secondary secondary-bg border border-button-secondary',
    'hover:text-button-secondary-hover hover:bg-button-secondary-hover hover:border-button-secondary-hover',
    'active:text-button-secondary-active active:bg-button-secondary-active active:border-button-secondary-active',
    'focus-visible:text-button-secondary-focus focus-visible:bg-button-secondary-focus focus-visible:border-button-secondary-focus'
  ].join(' '),
  tertiary: [
    '!rounded-full text-button-tertiary bg-button-tertiary border border-button-tertiary',
    'hover:text-button-tertiary-hover hover:bg-button-tertiary-hover hover:border-button-tertiary-hover',
    'active:text-button-tertiary-active primary-bg-active active:border-button-tertiary-active',
    'focus-visible:text-button-tertiary-focus focus-visible:bg-button-tertiary-focus focus-visible:border-button-tertiary-focus'
  ].join(' '),
  quaternary: [
    'text-button-quaternary bg-button-quaternary border border-button-quaternary shadow-primary',
    'hover:text-button-quaternary-hover hover:bg-button-quaternary-hover hover:border-button-quaternary-hover hover:shadow-secondary',
    'active:text-button-quaternary-active active:bg-button-quanternary-active active:border-button-quaternary-active active:shadow-secondary',
    'focus-visible:text-button-quaternary-focus focus-visible:bg-button-quaternary-focus focus-visible:border-button-quaternary-focus focus-visible:shadow-none'
  ].join(' ')
}

const sizeClassesMap: Record<NonNullable<PropsType['size']>, string> = {
  big: 'rounded-xl md:rounded-lg px-3 py-[9px] text-xs md:px-4 md:text-sm md:py-[9px]',
  small: 'rounded-xl px-3 py-[9px] text-xs'
}

const defaultLoadingClasses = '!text-transparent !bg-transparent !border-transparent'
const loadingClassesMap: Record<NonNullable<PropsType['severity']>, string> = {
  primary: `${defaultLoadingClasses}`,
  secondary: `${defaultLoadingClasses}`,
  tertiary: `${defaultLoadingClasses} `,
  quaternary: `${defaultLoadingClasses} !shadow-none`
}

const disabledClassesMap: Record<NonNullable<PropsType['severity']>, string> = {
  primary:
    'disabled:text-button-primary-disabled disabled:!bg-button-primary-disabled disabled:border-button-primary-disabled',
  secondary:
    'disabled:text-button-secondary-disabled disabled:bg-button-secondary-disabled disabled:border-button-secondary-disabled',
  tertiary:
    'disabled:text-button-tertiary-disabled disabled:bg-button-tertiary-disabled disabled:border-button-tertiary-disabled',
  quaternary:
    'disabled:text-button-quaternary-disabled disabled:bg-button-quaternary-disabled disabled:border-button-quaternary-disabled disabled:shadow-none'
}

const textClasses = [
  '!p-0 border-none shadow-none bg-button-text text-button-text',
  'hover:text-button-text-hover hover:bg-button-text-hover',
  'active:text-button-text-active active:bg-button-text-active',
  'focus-visible:text-button-text-focus focus-visible:bg-button-text-focus',
  'disabled:text-button-text-disabled disabled:bg-button-text-disabled'
].join(' ')

const defaultIconClasses = [
  '!rounded-full !border w-fit !shadow-none !bg-button-icon',
  'hover:!bg-button-icon-hover hover:!border-button-icon-hover',
  'active:!bg-button-icon-active active:border-button-icon-active',
  'focus-visible:!bg-button-icon-focus focus-visible:border-button-icon-focus',
  'disabled:!bg-button-icon-disabled disabled:bg-button-icon-disabled'
].join(' ')
const iconClassesMap: Record<NonNullable<PropsType['size']>, string> = {
  big: `${defaultIconClasses} !p-3 !border-button-icon `,
  small: `${defaultIconClasses} !p-2 !border-transparent`
}
const iconTransparentClassesMap: Record<NonNullable<PropsType['size']>, string> = {
  big: `${defaultIconClasses} !p-0 !bg-transparent focus-visible:!border-transparent active:!border-transparent hover:!border-transparent focus-visible:!bg-transparent active:!bg-transparent hover:!bg-transparent !border-transparent `,
  small: `${defaultIconClasses} !p-0 !bg-transparent !border-transparent focus-visible:!bg-transparent active:!bg-transparent hover:!bg-transparent focus-visible:!border-transparent active:!border-transparent hover:!border-transparent`
}

const computedClasses = computed(() =>
  [
    severityClassesMap[props.severity],
    sizeClassesMap[props.size],
    props.text ? textClasses : '',
    props.loading ? loadingClassesMap[props.severity] : '',
    props.icon ? iconClassesMap[props.size] : '',
    props.iconTransparent ? iconTransparentClassesMap[props.size] : '',
    props.disabled ? disabledClassesMap[props.severity] : ''
  ].join(' ')
)
</script>

<style scoped>
.primary-bg-active:active {
  background-image: var(--backgroundColor-button-primary-active);
}

.primary-bg-active:disabled,
.primary-bg-active.loading,
.primary-bg-active.text,
.primary-bg-active.icon {
  background-image: none;
}

.secondary-bg {
  position: relative;
  z-index: 0;
  overflow: hidden;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background-image: var(--backgroundColor-button-secondary-DEFAULT);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &:disabled::after,
  &.loading::after,
  &.text::after,
  &:active::after,
  &.active::after,
  &.icon:after {
    opacity: 0;
  }
}
</style>

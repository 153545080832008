<template>
  <button
    type="button"
    class="relative h-10 w-full rounded-xl text-xs text-calendar-text-primary hover:!no-underline"
    :disabled="dateIsDisabled"
    :class="[
      {
        'text-calendar-text-weekend': day.isWeekend,
        'bg-transparent !text-calendar-text-inactive opacity-70':
          !day.isThisMonth || (!isSameDay(today, day.value) && isAfter(today, day.value)),
        '!text-calendar-text-inactive': !dateIsAvailable(day),
        '!font-bold text-calendar-text-active': day.isToday,
        'z-10 !bg-calendar-active !text-light': dateIsActive(day),
        'z-0 ml-[-20%] !w-[140%] !rounded-none bg-calendar-range':
          !dateIsActive(day) && dateIsInRange(day)
      },
      dateIsActive(day) && activeDateClass
    ]"
    @click="emit('select', day)"
    @mouseenter.stop="isLgAndUp && showPopover($event)"
    @mouseleave.stop="isLgAndUp && hidePopover($event)"
  >
    {{ day.text }}

    <UiPopover v-if="!dateIsAvailable(props.day)" ref="popover">
      <div class="max-w-[120px] p-3 text-sm text-text-secondary">
        <UiDate
          :value="day.value"
          :template="isSameYear(new Date(), day.value) ? 'd MMMM' : 'd MMMM yyyy'"
        />
        мероприятие не проходит
      </div>
    </UiPopover>
  </button>
</template>

<script lang="ts" setup>
import { isAfter, isSameDay, isWithinInterval, isSameYear } from 'date-fns'
import { computed, ref } from 'vue'
import { useBreakpoint } from '../../lib'
import UiDate from '../UiDate.vue'
import UiPopover from '../UiPopover.vue'
import type { DatePickerDateValue } from './type'

type PropType = {
  day: DatePickerDateValue
  activeDateClass: string
  availableDates?: Date[]
  range?: boolean
  selectedDate?: Date | Date[]
}
type EmitType = {
  (e: 'select', day: DatePickerDateValue): void
}

const props = withDefaults(defineProps<PropType>(), {
  availableDates: undefined,
  range: false,
  selectedDate: undefined
})
const emit = defineEmits<EmitType>()
const {
  lg: [isLgAndUp]
} = useBreakpoint()
const today = new Date()
const popover = ref<typeof UiPopover | null>(null)
const showPopover = (e: MouseEvent) => {
  popover.value && popover.value.show(e)
}
const hidePopover = (e: MouseEvent) => {
  popover.value && popover.value.hide(e)
}

const dateIsDisabled = computed(
  () =>
    (!isSameDay(today, props.day.value) && isAfter(today, props.day.value)) ||
    !dateIsAvailable(props.day)
)

const dateIsAvailable = (date: DatePickerDateValue) => {
  if (!props.availableDates) return true

  for (const availableDate of props.availableDates) {
    if (isSameDay(availableDate, date.value)) return true
  }

  return false
}

/**
 * проверяет, что дата в выбранном диапазоне
 * @param date
 */
const dateIsInRange = (date: DatePickerDateValue) => {
  if (!props.range || !Array.isArray(props.selectedDate)) return false

  return isWithinInterval(date.value, {
    start: props.selectedDate[0],
    end: props.selectedDate[1]
  })
}

/**
 * проверяет, что даты активны
 * @param date
 */
const dateIsActive = (date: DatePickerDateValue) => {
  if (Array.isArray(props.selectedDate)) {
    return (
      date.isThisMonth &&
      (isSameDay(date.value, props.selectedDate[0]) || isSameDay(date.value, props.selectedDate[1]))
    )
  }

  return date.isThisMonth && isSameDay(date.value, props.selectedDate as Date)
}
</script>

<template>
  <div>
    <UiButton severity="primary" class="flex items-center gap-1" @click.stop="showPopover">
      <slot name="button">
        {{ modelValue.title }}
      </slot>
      <UiIcon
        name="chevron-down"
        class="size-3 transition-transform"
        :class="{ 'rotate-180': visible }"
      />
    </UiButton>
    <UiPopover ref="popover" @open="visible = true" @close="visible = false">
      <ul class="py-1.5">
        <li v-for="option in options" :key="option.title" class="text-medium text-sm">
          <UiButtonLink
            severity="primary"
            size="small"
            :class="{ '!text-text-primary': modelValue.title === option.title }"
            class="!text-medium w-full !px-3 !py-1.5 text-left text-sm"
            @click="change(option)"
          >
            {{ option.title }}
          </UiButtonLink>
        </li>
      </ul>
    </UiPopover>
  </div>
</template>

<script setup lang="ts" generic="T extends { title: string }">
import { ref, useTemplateRef } from 'vue'
import UiButton from './UiButton.vue'
import UiButtonLink from './UiButtonLink.vue'
import UiIcon from './UiIcon.vue'
import UiPopover from './UiPopover.vue'

type PropType = {
  modelValue: T
  options: T[]
}
type EmitType = {
  (e: 'update:modelValue', value: T): void
}

defineProps<PropType>()
const emit = defineEmits<EmitType>()

const visible = ref(false)
const change = (value: T) => {
  emit('update:modelValue', value)
  hidePopover()
}
const popover = useTemplateRef<typeof UiPopover>('popover')

const showPopover = (e: MouseEvent) => {
  popover.value?.toggle(e)
}
const hidePopover = () => {
  popover.value?.hide()
  visible.value = false
}
</script>

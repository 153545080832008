<template>
  <div>
    <div ref="visibleContent" class="relative" @click="!showFull && (showFull = true)">
      <div
        class="relative max-h-full"
        :style="{ maxHeight: showFull ? '100%' : `${maxHeight}px` }"
        :class="{
          'overflow-hidden': !showFull
        }"
      >
        <slot v-if="showButton && !showFull" name="gradient" />

        <slot />
      </div>
    </div>
    <UiButton v-if="showButton" text class="-ml-3 -mt-3 !p-3 text-sm" @click="showFull = !showFull">
      {{ showFull ? 'Скрыть описание' : 'Хочу прочитать описание полностью' }}
      <template #link-icon>
        <UiIcon
          name="chevron-down"
          class="inline size-3 transition-transform"
          :class="{ 'rotate-180': showFull }"
        />
      </template>
    </UiButton>
    <div ref="defaultContent" class="absolute -z-10 h-full opacity-0">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Nullable } from 'ts-helpers'
import { onMounted, ref } from 'vue'
import UiButton from './UiButton.vue'
import UiIcon from './UiIcon.vue'

type PropType = {
  maxHeight?: number
}

const props = withDefaults(defineProps<PropType>(), {
  maxHeight: 448
})

const showFull = ref(false)
const showButton = ref(false)
const defaultContent = ref<Nullable<HTMLElement>>(null)
const visibleContent = ref<Nullable<HTMLElement>>(null)
const calculateButtonVisibility = () => {
  if (!visibleContent.value || !defaultContent.value) return

  const { offsetHeight: defaultHeight } = defaultContent.value
  const { offsetHeight: visibleHeight } = visibleContent.value

  showButton.value = defaultHeight >= props.maxHeight && defaultHeight > visibleHeight
}
const hideDefaultContent = () => {
  defaultContent.value?.remove()
}

onMounted(() => {
  calculateButtonVisibility()
  hideDefaultContent()
})
</script>

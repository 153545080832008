<template>
  <div class="absolute inset-0 size-full" :class="stylesMap[severity]" />
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    severity?: 'primary' | 'secondary' | 'primary_solid'
  }>(),
  {
    severity: 'primary'
  }
)

const stylesMap: Record<(typeof props)['severity'], string> = {
  primary: 'gradient-primary',
  primary_solid: 'gradient-primary-solid',
  secondary: 'gradient-secondary'
}
</script>

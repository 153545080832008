<template>
  <Component
    :is="tag"
    :class="roundedClassesMap[rounded]"
    class="relative mx-auto w-full max-w-[1192px] p-0 px-4 lg:px-7"
  >
    <slot />
  </Component>
</template>

<script lang="ts" setup>
type PropType = {
  tag?: string
  rounded?: 'full' | 'top' | 'bottom' | 'none'
}

withDefaults(defineProps<PropType>(), {
  tag: 'div',
  rounded: 'none'
})

const roundedClassesMap: Record<NonNullable<PropType['rounded']>, string> = {
  full: 'rounded-4xl',
  top: 'rounded-t-4xl',
  bottom: 'rounded-b-4xl',
  none: 'rounded-none'
}
</script>

<template>
  <div class="w-fit" :class="[computedClasses, { absolute: positionX || positionY }]">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

type PropType = {
  severity?: 'primary'
  size?: 'big' | 'small'
  positionY?: 'top' | 'bottom' | 'center' | ''
  positionX?: 'left' | 'right' | 'center' | ''
}

const props = withDefaults(defineProps<PropType>(), {
  severity: 'primary',
  size: 'small',
  positionX: '',
  positionY: ''
})

const severityClassesMap: Record<NonNullable<PropType['severity']>, string> = {
  primary: 'text-badge-primary-text bg-badge-primary shadow-primary'
}
const sizeClassesMap: Record<NonNullable<PropType['size']>, string> = {
  big: 'rounded-[4px] p-1 text-normal text-xs',
  small: 'rounded-[4px] p-1 text-normal text-xxs'
}
const positionXClassesMap: Record<NonNullable<PropType['positionX']>, string> = {
  left: 'left-0 right-auto',
  center: 'inset-x-center',
  right: 'right-0 left-auto',
  '': ''
}
const positionYClassesMap: Record<NonNullable<PropType['positionY']>, string> = {
  top: 'top-0 bottom-auto',
  center: 'inset-y-center',
  bottom: 'top-auto bottom-0',
  '': ''
}

const computedClasses = computed(() =>
  [
    severityClassesMap[props.severity],
    sizeClassesMap[props.size],
    positionXClassesMap[props.positionX],
    positionYClassesMap[props.positionY]
  ].join(' ')
)
</script>

<template>
  <UiButton
    v-if="visible"
    class="!flex max-h-[60px] items-center overflow-hidden !rounded-2xl border !bg-primary !p-0 text-base font-semibold text-text-primary"
  >
    <div class="mr-4 h-[60px] w-full max-w-1/5">
      <UiPicture
        :src="image"
        img-classes="!object-cover"
        :alt="title"
        :options="{ height: 120 }"
        class="h-full !w-full !max-w-max object-cover"
      >
        <template #default-image>
          <slot name="default-image" />
        </template>
      </UiPicture>
    </div>

    <p class="py-4.5">
      <slot name="title" />
    </p>

    <UiIcon name="chevron-down" class="ml-auto mr-2 size-3 min-w-3 -rotate-90 text-icon-primary" />
  </UiButton>
</template>

<script lang="ts" setup>
import type { Nullable } from 'ts-helpers'
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { getElementByGlobalId, GlobalIds } from '../config'
import UiButton from './UiButton.vue'
import UiIcon from './UiIcon.vue'
import UiPicture from './UiPicture.vue'

type PropType = {
  image: string
  title: string
  visible: boolean
  observableElementId: GlobalIds
}
type EmitType = {
  (e: 'update:visible', value: boolean): void
}

const props = defineProps<PropType>()
const emit = defineEmits<EmitType>()

const observableElement = ref<Nullable<HTMLElement>>(null)
let count = 0
let intervalId: NodeJS.Timeout | null = null
onMounted(() => {
  intervalId = setInterval(() => {
    if (count === 20 || observableElement.value) {
      intervalId && clearInterval(intervalId)
    } else {
      observableElement.value = getElementByGlobalId(props.observableElementId)
    }
  }, 1000)
})
watch(
  () => observableElement.value,
  () => initObserver()
)

// IntersectionObserver
let observer: Nullable<IntersectionObserver> = null
const intersectionObserverOptions: IntersectionObserverInit = {
  rootMargin: '0px',
  threshold: 0.1
}
const initObserver = () => {
  if (!observableElement.value) {
    // из за немгновенной записи ссылки на html,
    // убираем моргание кнопки
    emit('update:visible', false)

    return
  }

  observer = new IntersectionObserver(intersectionObserverCallBack, intersectionObserverOptions)
  observer.observe(observableElement.value)
}

const intersectionObserverCallBack: IntersectionObserverCallback = function (
  entries: IntersectionObserverEntry[]
) {
  entries.forEach((entry) => {
    const { isIntersecting } = entry

    emit('update:visible', !isIntersecting)
  })
}

const destroyObserver = () => {
  if (!observer || !observableElement.value) return

  observer.unobserve(observableElement.value)
  observer = null
}

onBeforeUnmount(() => {
  destroyObserver()
  intervalId && clearInterval(intervalId)
})
</script>

<template>
  <UiButton
    type="button"
    class="overflow-hidden whitespace-nowrap"
    :class="{ '!border-transparent': !inactive && value }"
  >
    <UiGradientBackground v-if="!inactive && value" severity="primary" />
    <div class="relative flex items-center gap-1">
      <UiIcon name="calendar" class="mr-1 size-5" />
      <template v-if="!inactive && value">
        <template v-if="Array.isArray(value)">
          <UiDate :value="value[0]" template="dd MMM" /> —
          <UiDate :value="value[1]" template="dd MMM" />
        </template>
        <template v-else>
          <UiDate :value="value" template="dd MMM" />
        </template>
        <UiIcon name="x-mark" class="size-5" @click.stop="emit('reset')" />
      </template>
      <template v-else> выбрать, когда </template>
    </div>
  </UiButton>
</template>

<script setup lang="ts">
import UiButton from '../UiButton.vue'
import UiDate from '../UiDate.vue'
import UiGradientBackground from '../UiGradientBackground.vue'
import UiIcon from '../UiIcon.vue'

type PropType = {
  value?: Date | Date[]
  inactive?: boolean
}
type EmitType = {
  (e: 'reset'): void
}

withDefaults(defineProps<PropType>(), {
  value: undefined,
  inactive: false
})
const emit = defineEmits<EmitType>()
</script>

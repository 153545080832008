<template>
  <svg
    class="absolute -left-px -top-px z-[11] size-[calc(100%_+_2px)]"
    viewBox="0 0 268 168"
    fill="none"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 54V0H82C82 0 13.5 1.20265 7.5 6C0.621202 11.5 0 54 0 54Z"
      fill="var(--backgroundColor-primary)"
    />
    <path
      d="M0 114V168H82C82 168 13.5 166.797 7.5 162C0.621202 156.5 0 114 0 114Z"
      fill="var(--backgroundColor-primary)"
    />
    <path
      d="M267.5 69.5L268 0H140C140 0 255 2 260.5 6C267.623 11.1802 267.5 69.5 267.5 69.5Z"
      fill="var(--backgroundColor-primary)"
    />
    <path
      d="M267.5 98.5L268 168H140C140 168 255 166 260.5 162C267.623 156.82 267.5 98.5 267.5 98.5Z"
      fill="var(--backgroundColor-primary)"
    />
  </svg>
</template>

<template>
  <TransitionFade>
    <header
      v-show="show"
      :class="alwaysVisible ? 'sticky' : 'fixed'"
      class="inset-0 z-[25] h-fit bg-primary py-2 text-base font-semibold md:hidden"
    >
      <UiContainer class="flex items-center">
        <UiButton icon-transparent class="-ml-3 size-9 min-w-9" @click="goBack()">
          <UiIcon name="chevron-down" class="size-5 rotate-90 text-icon-secondary" />
        </UiButton>
        <div class="line-clamp-1 w-full">
          <slot />
        </div>
      </UiContainer>
    </header>
  </TransitionFade>
</template>

<script setup lang="ts">
import { TransitionFade } from '@morev/vue-transitions'
import throttle from 'lodash/throttle.js'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { useGoBack } from '../composables'
import UiButton from './UiButton.vue'
import UiContainer from './UiContainer.vue'
import UiIcon from './UiIcon.vue'

const props = withDefaults(
  defineProps<{
    border?: number
    alwaysVisible?: boolean
  }>(),
  {
    border: 150,
    alwaysVisible: false
  }
)
const show = ref(props.alwaysVisible)

const { goBack } = useGoBack()
const scrollHandler = throttle(function () {
  document.documentElement.scrollTop >= props.border ? (show.value = true) : (show.value = false)
}, 100)

onMounted(() => !props.alwaysVisible && window.addEventListener('scroll', scrollHandler))

onBeforeUnmount(() => !props.alwaysVisible && window.removeEventListener('scroll', scrollHandler))
</script>

<template>
  <Component
    :is="tag"
    :type="type"
    :class="['group relative w-fit transition-colors', computedClasses]"
    :disabled="disabled"
    @click="!disabled && emit('click', $event)"
  >
    <slot />
    <span
      v-if="icon"
      class="inline-flex items-center transition-transform group-hover:translate-x-0.5"
    >
      &nbsp;<slot name="link-icon">
        <UiIcon name="arrow-right" class="inline-flex size-3" />
      </slot>
    </span>
  </Component>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import UiIcon from './UiIcon.vue'

type PropsType = {
  severity?: 'primary' | 'secondary'
  size?: 'big' | 'medium' | 'small'
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  tag?: string
  icon?: boolean
}
type EmitType = {
  (e: 'click', event: MouseEvent): void
}

const props = withDefaults(defineProps<PropsType>(), {
  severity: 'primary',
  tag: 'button',
  type: 'button',
  size: 'big',
  icon: false
})
const emit = defineEmits<EmitType>()

const severityClassesMap: Record<NonNullable<PropsType['severity']>, string> = {
  primary: [
    'text-button-link-primary bg-button-link-primary  border border-button-link-primary',
    'hover:text-button-link-primary-hover hover:bg-button-link-primary-hover hover:border-button-link-primary-hover',
    'active:text-button-link-primary-active active:bg-button-link-primary-active active:border-button-link-primary-active',
    'focus-visible:text-button-link-primary-focus focus-visible:bg-button-link-primary-focus focus-visible:border-button-link-primary-focus'
  ].join(' '),
  secondary: [
    'text-button-link-secondary bg-button-link-secondary  border border-button-link-secondary',
    'hover:text-button-link-secondary-hover hover:bg-button-link-secondary-hover hover:border-button-link-secondary-hover',
    'active:text-button-link-secondary-active active:bg-button-link-secondary-active active:border-button-link-secondary-active',
    'focus-visible:text-button-link-secondary-focus focus-visible:bg-button-link-secondary-focus focus-visible:border-button-link-secondary-focus'
  ].join(' ')
}

const sizeClassesMap: Record<NonNullable<PropsType['size']>, string> = {
  big: 'text-xl font-semibold',
  medium: 'text-base font-semibold',
  small: 'text-sm font-normal'
}

const disabledClassesMap: Record<NonNullable<PropsType['severity']>, string> = {
  primary:
    'disabled:text-button-link-primary-disabled disabled:!bg-button-link-primary-disabled disabled:border-button-link-primary-disabled',
  secondary:
    'disabled:text-button-link-secondary-disabled disabled:!bg-button-link-secondary-disabled disabled:border-button-link-secondary-disabled'
}

const computedClasses = computed(() =>
  [
    severityClassesMap[props.severity],
    sizeClassesMap[props.size],
    props.disabled ? disabledClassesMap : ''
  ].join(' ')
)
</script>

<template>
  <main class="flex flex-col items-center justify-center !bg-secondary">
    <div
      class="text-main relative z-10 w-full max-w-[296px] translate-x-[-3px] translate-y-[-5px] -rotate-3 rounded-lg bg-primary px-2 py-7 text-center text-xs font-medium"
    >
      <slot />
      <ul class="absolute bottom-[-0.5px] right-4 flex w-full justify-end gap-4.5">
        <li v-for="item in 7" :key="item" class="h-1 w-5 rounded-full !bg-secondary" />
      </ul>
      <div
        v-for="round in 2"
        :key="'round' + round"
        :class="round > 1 ? '-right-5' : '-left-5'"
        class="absolute -bottom-5 size-10 rounded-full !bg-secondary"
      />
    </div>
    <div
      class="relative flex max-w-[296px] flex-col rounded-lg bg-primary px-4 pb-12 pt-8 text-center"
    >
      <div
        v-for="round in 2"
        :key="'round1' + round"
        :class="round > 1 ? '-right-5' : '-left-5'"
        class="absolute -top-4 size-10 rounded-full !bg-secondary"
      />
      <ul class="absolute right-8 top-[-0.5px] flex w-full justify-end gap-4.5">
        <li v-for="item in 6" :key="item + '1'" class="h-1 w-5 rounded-full !bg-secondary" />
      </ul>
      <svg
        width="15"
        height="13"
        viewBox="0 0 15 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="mx-auto mb-2 text-text-primary"
      >
        <path
          d="M2.88998 8.51912C1.9237 7.28386 1.16434 5.77134 0.994446 4.29319C0.788714 2.50298 2.37393 -0.0920145 4.93041 0.831757C6.45083 1.38094 7.59596 2.71915 7.90783 4.05766C7.6442 2.92233 10.0048 1.16981 11.1248 0.824102C12.641 0.356224 14.0832 0.877679 14.6122 2.17922C16.0544 5.72166 12.946 9.59769 10.0065 11.9345C7.99398 13.5352 6.59952 12.0802 5.12304 10.8042C4.30408 10.0965 3.53578 9.34483 2.88998 8.51912Z"
          fill="currentColor"
          fill-opacity="0.5"
        />
      </svg>
      <p class="mb-6 text-sm font-medium">
        Не расстраивайтесь,<br />
        у нас много других интересных событий!
      </p>
      <ul class="mb-9 flex flex-wrap justify-center gap-2">
        <li v-for="category in categoriesNav" :key="category.id">
          <UiButton
            tag="RouterLink"
            :to="{ name: 'CategoryItemView', params: { id: category.id } }"
            severity="secondary"
            class="block first-letter:lowercase"
          >
            {{ category.name }}
          </UiButton>
        </li>
      </ul>
      <p class="mb-1 text-sm font-medium text-text-secondary">или просто</p>
      <UiButton tag="RouterLink" severity="secondary" class="mx-auto block w-fit" to="/">
        открыть главную
      </UiButton>
    </div>
  </main>
</template>

<script lang="ts" setup>
import { useConfiguration } from '@/5_entities/Configuration'
import UiButton from './UiButton.vue'

const { categoriesNav } = useConfiguration()
</script>

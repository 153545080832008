<template>
  <label class="relative flex flex-col" v-bind="containerAttrs">
    <slot name="label" />
    <span class="relative">
      <slot name="prepend" />
      <input
        v-bind="{ ...$attrs }"
        ref="inputElement"
        :value="modelValue"
        class="w-full text-base outline-0 placeholder:text-text-secondary disabled:opacity-50"
        :class="[
          severityClassesMap[severity],
          sizeClassesMap[severity][size],
          { '!border-error': isInvalidInput, '!border-success': success }
        ]"
        :type="type"
        @input="emit('update:modelValue', ($event.target as HTMLInputElement).value)"
        @change="emit('change', ($event.target as HTMLInputElement).value)"
      />
      <slot v-if="type !== 'search' || (type === 'search' && !modelValue)" name="append" />
      <button
        v-if="type === 'search' && modelValue"
        type="button"
        class="absolute right-4 flex size-5 items-center justify-center rounded-full text-icon-tertiary inset-y-center"
        @click="emit('update:modelValue', '')"
      >
        <UiIcon name="x-mark" class="size-4" />
      </button>
    </span>
    <TransitionFade :duration="200">
      <span v-show="isInvalidInput" class="text-xs text-error">{{ errors[0] }}</span>
    </TransitionFade>
  </label>
</template>

<script lang="ts" setup>
import { TransitionFade } from '@morev/vue-transitions'
import type { Nullable } from 'ts-helpers'
import { computed, ref } from 'vue'
import UiIcon from './UiIcon.vue'

type PropType = {
  modelValue: string | number
  invalid?: boolean
  severity?: 'primary' | 'secondary' | 'tertiary'
  size?: 'big' | 'small'
  type?: 'text' | 'search' | 'email' | 'number'
  errors?: string[]
  success?: boolean
  containerAttrs?: any
}

type EmitType = {
  (e: 'update:modelValue', value: string): void
  (e: 'change', value: string): void
}

const props = withDefaults(defineProps<PropType>(), {
  invalid: false,
  severity: 'primary',
  size: 'big',
  type: 'text',
  success: false,
  errors: () => [],
  containerAttrs: () => {}
})

const emit = defineEmits<EmitType>()

defineOptions({
  inheritAttrs: false
})

const isInvalidInput = computed(() => props.errors.length)

const severityClassesMap: Record<NonNullable<PropType['severity']>, string> = {
  primary: [
    'text-input-primary bg-input-primary rounded-full border-input-primary border',
    'hover:text-input-primary-hover hover:bg-input-primary-hover hover:border-input-primary-hover',
    'focus:text-input-primary-focus focus:bg-input-primary-focus focus:border-input-primary-focus',
    'disabled:text-input-primary-disabled disabled:bg-input-primary-disabled disabled:border-input-primary-disabled'
  ].join(' '),
  secondary: [
    'text-input-secondary bg-input-secondary rounded-xl border-input-secondary border',
    'hover:text-input-secondary-hover hover:bg-input-secondary-hover hover:border-input-secondary-hover',
    'focus:text-input-secondary-focus focus:bg-input-secondary-focus focus:border-input-secondary-focus',
    'disabled:text-input-secondary-disabled disabled:bg-input-secondary-disabled disabled:border-input-secondary-disabled'
  ].join(' '),
  tertiary: [
    'text-input-tertiary bg-input-tertiary rounded-lg border-input-tertiary border',
    'hover:text-input-tertiary-hover hover:bg-input-tertiary-hover hover:border-input-tertiary-hover',
    'focus:text-input-tertiary-focus focus:bg-input-tertiary-focus focus:border-input-tertiary-focus',
    'disabled:text-input-tertiary-disabled disabled:bg-input-tertiary-disabled disabled:border-input-tertiary-disabled'
  ].join(' ')
}

const sizeClassesMap: Record<
  NonNullable<PropType['severity']>,
  Record<NonNullable<PropType['size']>, string>
> = {
  primary: {
    big: 'py-[9px] px-5 text-sm',
    small: 'py-2.5 px-4 text-xs'
  },
  secondary: {
    big: 'py-[9px] px-3 text-sm',
    small: 'py-2.5 px-3 text-xs'
  },
  tertiary: {
    big: 'py-[9px] px-3 text-sm',
    small: 'py-2.5 px-3 text-xs'
  }
}

const inputElement = ref<Nullable<HTMLInputElement>>(null)

defineExpose({
  inputElement
})
</script>

<template>
  <div
    ref="datePickerContainer"
    class="relative h-full overflow-y-scroll bg-primary"
    @scroll="onPickerScroll"
    @touchmove="onInteractionTimerReload"
    @touchend="onInteractionTimerReload"
    @mousemove="onInteractionTimerReload"
  >
    <header class="sticky inset-0 z-20 bg-primary pb-2">
      <div class="flex items-center" :class="headerClasses">
        <slot name="header" />
        <TransitionFade>
          <UiButton
            v-if="showReset"
            v-show="selectedDate"
            text
            class="ml-2 !pt-px text-xs text-text-tertiary"
            @click="resetSelection"
          >
            сбросить
          </UiButton>
        </TransitionFade>
      </div>
      <UiDateHelpers
        :model-value="selectedDate"
        :available-dates="availableDates"
        class="mb-2"
        @update:model-value="onHelperSelect"
      />
      <ul class="grid grid-cols-7">
        <li
          v-for="(day, index) in headerDays"
          :key="day"
          :class="
            index === 6 || index === 5
              ? '!text-calendar-text-weekend'
              : 'text-calendar-text-primary'
          "
        >
          <p class="text-center text-xs">
            {{ day }}
          </p>
        </li>
      </ul>
    </header>
    <UiContainer class="flex flex-col gap-y-2 !px-0 pt-4">
      <div
        v-for="month in visibleMonths"
        :key="getMonth(month) + getYear(month)"
        class="overflow-hidden"
      >
        <header class="mb-4 text-center">
          <UiTitle severity="h5">
            <UiDate :value="month" template="LLLL yyyy" class="capitalize" />
          </UiTitle>
        </header>
        <ul class="grid grid-cols-7">
          <li v-for="day in getDatesInMonth(month)" :key="day.value.toString()">
            <DatePickerCell
              :day="day"
              :active-date-class="ACTIVE_DATE_CLASS"
              :available-dates="availableDates"
              :range="range"
              :selected-date="selectedDate"
              @select="selectDate"
            />
          </li>
        </ul>
      </div>
    </UiContainer>
    <UiContainer class="sticky bottom-12 left-0 z-20 !px-0">
      <TransitionFade>
        <UiButton
          v-show="showConfirmButton"
          severity="secondary"
          class="w-full"
          @click="onConfirmClick"
        >
          <slot name="confirm-text"> Показать мероприятия </slot>
        </UiButton>
      </TransitionFade>
    </UiContainer>
  </div>
</template>

<script lang="ts" setup>
import { TransitionFade } from '@morev/vue-transitions'
import {
  getDaysInMonth,
  addMonths,
  getMonth,
  getYear,
  set,
  isWeekend,
  previousMonday,
  isBefore,
  isAfter,
  isToday,
  isSameMonth,
  isSameDay
} from 'date-fns'
import throttle from 'lodash/throttle.js'
import type { Nullable, Undefinable } from 'ts-helpers'
import { computed, ref, shallowRef } from 'vue'
import { getMilliseconds } from '../../lib'
import UiButton from '../UiButton.vue'
import UiContainer from '../UiContainer.vue'
import UiDate from '../UiDate.vue'
import UiDateHelpers from '../UiDateHelpers.vue'
import UiTitle from '../UiTitle.vue'
import DatePickerCell from './DatePickerCell.vue'
import type { DatePickerDateValue } from './type'

type PropType = {
  range?: boolean
  needConfirm?: boolean
  modelValue?: Date | Date[]
  availableDates?: Date[]
  showReset?: boolean
  headerClasses?: string
}

type EmitType = {
  (e: 'update:modelValue', date: Undefinable<Date | Date[]>): void
  (e: 'close'): void
}

const props = withDefaults(defineProps<PropType>(), {
  range: false,
  needConfirm: false,
  modelValue: undefined,
  availableDates: undefined,
  showReset: false,
  headerClasses: ''
})
const emit = defineEmits<EmitType>()

//header
//обработка хелперов
/**
 * добавляет в список отоброжаемых месяцев
 * дефолтный список месяцев
 */
const addDefaultInvisibleMonth = () => {
  const monthIsNotVisible = !visibleMonths.value.find((date) =>
    isSameMonth(date, selectedDate.value as NonNullable<Date>)
  )

  if (monthIsNotVisible) {
    visibleMonths.value.unshift(...createDefaultMonths())
  }
}
/**
 * удаляет лишние месяца,
 * которые идут после дефолтных
 */
const removeExcessMonth = () => {
  setTimeout(() => {
    const visibleMonthLength = createDefaultMonths().length
    visibleMonths.value.splice(visibleMonthLength, visibleMonths.value.length - visibleMonthLength)
  }, 200)
}

const onHelperSelect = (value?: Date | Date[]) => {
  selectedDate.value = value

  addDefaultInvisibleMonth()
  goToActiveDate()
  removeExcessMonth()
  !props.needConfirm && confirmDate()
}

// подсказки дней
const headerDays = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']

//logic
// показываем текущий + 6 месяцев
// по мере скролла добавляем или удаляем месяцы из массива

const datePickerContainer = ref<Nullable<HTMLElement>>(null)
const today = new Date()

/**
 * создает текущий месяц + 6 далее
 */
const createDefaultMonths = (): Date[] => [
  today,
  addMonths(today, 1),
  addMonths(today, 2),
  addMonths(today, 3),
  addMonths(today, 4),
  addMonths(today, 5),
  addMonths(today, 6)
]
const visibleMonths = ref<Date[]>(createDefaultMonths())

// выбор даты
const ACTIVE_DATE_CLASS = 'is-active'
const selectedDate = shallowRef<Undefinable<Date | Date[]>>(props.modelValue ?? undefined)
/**
 * скроллит календарь к ативной дате
 */
const goToActiveDate = () => {
  disableScrollHandler = true
  const getActiveDate = () => {
    if (!datePickerContainer.value) return

    return datePickerContainer.value.querySelector(`.${ACTIVE_DATE_CLASS}`)
  }

  setTimeout(() => {
    getActiveDate()?.scrollIntoView({
      block: 'center',
      behavior: 'smooth'
    })
    disableScrollHandler = false
  })
}
/**
 * выбирает дату
 * @param day
 */
// таймер, дающий время для выбора второй даты в диапазоне
let lastDateSelectionTimer: Undefinable<NodeJS.Timeout> = undefined
/**
 * сбрасывает таймер для выбора второй даты
 */
const resetLastDateSelectionTimer = () => {
  clearTimeout(lastDateSelectionTimer)
  lastDateSelectionTimer = undefined
}
/**
 * стартует таймер для выбора второй даты
 */
const startDateSelectionTimer = () => {
  lastDateSelectionTimer = setTimeout(
    () => resetLastDateSelectionTimer(),
    getMilliseconds.inSeconds(1.5)
  )
}

/**
 * перезапускаем таймер при взаимодействии пользователя со страницей
 */
const onInteractionTimerReload = () => {
  if (!lastDateSelectionTimer || !props.range) return

  resetLastDateSelectionTimer()
  startDateSelectionTimer()
}

/**
 * выбор даты
 * @param day
 */
const selectDate = (day: DatePickerDateValue) => {
  const isAnotherMonth = !day.isThisMonth
  const isFirstClick = !selectedDate.value
  const isClickAfterTimeout = selectedDate.value && !lastDateSelectionTimer
  const isSameDateRepeatedClick =
    selectedDate.value &&
    !Array.isArray(selectedDate.value) &&
    isSameDay(selectedDate.value, day.value)

  const setDayAsFirstDate = () => (selectedDate.value = day.value)
  const setDateRange = () => {
    selectedDate.value = [selectedDate.value as Date, day.value]
    selectedDate.value.sort((a, b) => {
      if (isBefore(a, b)) return -1
      if (isAfter(a, b)) return 1

      return 0
    })
  }
  const setDate = () => {
    if (!isAnotherMonth && isSameDateRepeatedClick) {
      resetLastDateSelectionTimer()

      return (selectedDate.value = undefined)
    }

    if (isFirstClick || isClickAfterTimeout || !props.range) {
      startDateSelectionTimer()
      setDayAsFirstDate()

      return isAnotherMonth ? goToActiveDate() : undefined
    }

    setDateRange()

    return resetLastDateSelectionTimer()
  }

  setDate()
  !props.needConfirm && confirmDate()
}

/**
 * сбрасывает выбор
 */
const resetSelection = () => {
  selectedDate.value = undefined
  !props.needConfirm && confirmDate()
}
/**
 * возвращает дни в месяце
 * @param month
 */
const getDatesInMonth = (month: Date): DatePickerDateValue[] => {
  const createDate = (n: number, date: Date, isThisMonth: boolean): DatePickerDateValue[] => {
    return new Array(n).fill(0).map((_, i) => {
      const dateValue = set(date, { date: i + 1 })

      return {
        text: i + 1,
        value: dateValue,
        isThisMonth,
        isWeekend: isWeekend(dateValue),
        isToday: isToday(dateValue)
      }
    })
  }

  const prevMonth = addMonths(month, -1)
  const nextMonth = addMonths(month, 1)

  const days = [
    ...createDate(getDaysInMonth(prevMonth), prevMonth, false).filter(({ value }) => {
      const lastMonday = previousMonday(set(month, { date: 2 }))

      return !isBefore(value, lastMonday)
    }),
    ...createDate(getDaysInMonth(month), month, true),
    ...createDate(getDaysInMonth(nextMonth), nextMonth, false)
  ].filter((_, i) => i <= 41)

  const lastWeek = days.slice(-7)
  const isLastWeekFromNextMonth =
    lastWeek.filter((item) => !isSameMonth(month, item.value)).length === 7

  return isLastWeekFromNextMonth ? days.slice(0, 35) : days
}
/**
 * обрабатывает скролл календаря
 */
let disableScrollHandler = false

const onPickerScroll = throttle(function () {
  if (disableScrollHandler || !datePickerContainer.value) return

  const { scrollTop, scrollHeight, clientHeight } = datePickerContainer.value

  const LOADING_BORDER = 120
  const topBorder = scrollTop <= LOADING_BORDER
  const bottomBorder = scrollHeight - (scrollTop + clientHeight) <= LOADING_BORDER

  if (topBorder) {
    onScrollTopHandler()
    onScrollTopHandler()
  }
  if (bottomBorder) {
    onScrollDownHandler()
    onScrollDownHandler()
  }
}, 1)

const addTimeout = () => {
  disableScrollHandler = true
  setTimeout(() => (disableScrollHandler = false))
}
/**
 * добавляет месяц сверху
 */
const onScrollTopHandler = () => {
  const firstMonth = visibleMonths.value[0]

  if (isSameMonth(firstMonth, today)) return

  visibleMonths.value.unshift(addMonths(firstMonth, -1))
  visibleMonths.value.pop()
  addTimeout()
}
/**
 * добавляет месяц снизу
 */
const onScrollDownHandler = () => {
  const lastMonth = visibleMonths.value[visibleMonths.value.length - 1]
  visibleMonths.value.push(addMonths(lastMonth, 1))
  visibleMonths.value.shift()
  addTimeout()
}

// кнопка подтверждения
const showConfirmButton = computed(
  () => props.needConfirm && (props.showReset || selectedDate.value)
)
/**
 * выбрасывает выбранную дату наружу
 */
const confirmDate = () => emit('update:modelValue', selectedDate.value)
const onConfirmClick = () => {
  confirmDate()
  emit('close')
}
</script>

<template>
  <template v-if="src && !imageNotLoaded">
    <img
      v-if="src && isFileFormatSvg"
      v-bind="{ src, ...$attrs }"
      :alt="($attrs.alt as string) || src"
      :class="$attrs.class"
      class="bg-secondary"
      :loading="lazy ? 'lazy' : undefined"
    />
    <picture v-else-if="src && !isProxyLoadError" :class="$attrs.class">
      <source
        v-for="(option, key) in sizeOptions"
        :key="'options' + option"
        :srcset="getImageProxyUrl(src, option, 'webp')"
        :media="`(max-width: ${Breakpoints[key as keyof typeof Breakpoints]}px)`"
        type="image/webp"
      />
      <source :srcset="pictureUrl.webp" type="image/webp" />
      <img
        v-bind="{ src }"
        :loading="lazy ? 'lazy' : undefined"
        :alt="($attrs.alt as string) || src"
        :class="imgClasses"
        class="size-full object-contain"
        @error="onProxyImageLoadError"
      />
    </picture>
    <img
      v-else
      class="bg-secondary"
      v-bind="{ src, ...$attrs }"
      :class="$attrs.class"
      :alt="($attrs.alt as string) || src"
      @error="onImageLoadError"
    />
  </template>
  <slot v-else name="default-image">
    <picture class="bg-secondary object-cover">
      <source :srcset="`/${defaultName}.webp`" type="image/webp" />
      <img
        v-bind="{ src }"
        :loading="lazy ? 'lazy' : undefined"
        alt="Не удалось загрузить изображение"
        :class="$attrs.class"
        @error="onImageLoadError"
      />
    </picture>
  </slot>
</template>

<script lang="ts" setup>
import type { PictureSizesOptions } from 'img-proxy'
import { computed } from 'vue'
import { ref } from 'vue'
import { useImgProxy } from '../composables'
import { Breakpoints } from '../config'

type PropType = {
  src: string
  options?: any
  sizeOptions?: PictureSizesOptions
  lazy?: boolean
  defaultName?: 'default' | 'default_venue'
  imgClasses?: string
}

const props = withDefaults(defineProps<PropType>(), {
  options: () => ({}),
  sizeOptions: () => ({}),
  lazy: false,
  defaultName: 'default',
  imgClasses: ''
})

const imageNotLoaded = ref(false)
const isFileFormatSvg = computed(() => props.src?.split('.').pop() === 'svg')

const { getImageProxyUrl } = useImgProxy()
const pictureUrl = computed(() => {
  return {
    webp: props.src && getImageProxyUrl(props.src, props.options, 'webp')
  }
})
const isProxyLoadError = ref(false)

const onProxyImageLoadError = () => {
  isProxyLoadError.value = true
}

const onImageLoadError = () => {
  imageNotLoaded.value = true
}
</script>
